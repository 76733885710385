<template>
  <div class="auth-page">
    <img src="~@/assets/img/login-bg.png" />

    <div class="auth-content">
      <div class="login-page">
        <div class="login-form" id="login-form">

          <div class="login-title-new animated fadeInRight" style="animation-delay: .3s; -webkit-animation-delay: .3s; ">
            <span>{{ value }}</span>
          </div>

          <!-- login form -->
          <el-form
            :model="dataForm"
            class="form-content"
            :rules="dataRule"
            ref="dataForm"
            @keyup.enter.native="dataFormSubmit()"
            status-icon>
            <el-row class="input__wrappper animated fadeInRight" style="animation-delay: .0s; -webkit-animation-delay: .0s; ">
              <el-form-item prop="userNo">
                <el-input
                  v-model.trim="dataForm.userNo"
                  size="medium"
                  class="login-input"
                  prefix-icon="el-icon-user"
                  autocomplete="off"
                  placeholder=" 请输入登录账号"></el-input>
              </el-form-item>
            </el-row>

            <el-row class="input__wrappper animated fadeInRight" style="animation-delay: .2s; -webkit-animation-delay: .2s; ">
              <el-form-item prop="password">
                <el-input
                  v-model.trim="dataForm.password"
                  size="medium"
                  class="login-input"
                  prefix-icon="el-icon-key"
                  type="password"
                  autocomplete="off"
                  placeholder=" 请输入登录密码"></el-input>
              </el-form-item>
            </el-row>

            <el-row class="input__wrappper animated fadeInRight" style="animation-delay: .4s; -webkit-animation-delay: .4s; ">
              <el-form-item prop="captcha">
                <el-row type="flex" align="middle">
                  <el-input
                    type="text"
                    size="medium"
                    class="login-captcha"
                    prefix-icon="el-icon-view"
                    v-model="dataForm.captcha"
                    placeholder=" 请输入验证码"></el-input>
                  <img alt="换一张" :src="captchaPath" id="validaId" class="validate__img" style="height:35px;width:120px;cursor:pointer;margin-top:0px;display:inline;padding-left: 3px;margin-right: -5px;" @click="getCaptcha()" />
                </el-row>
              </el-form-item>
            </el-row>

            <el-row class="login-item-center animated fadeInRight" style="animation-delay: .5s; -webkit-animation-delay: .5s; ">
              <el-button
                size="medium"
                :loading="logining"
                name="submit"
                type="primary"
                @click="dataFormSubmit()">{{ logining ? '正在登录...' : '登   录' }}</el-button>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>

    <!-- auth -->
    <div class="auth-footer">
      <p></p>
    </div>

  </div>
</template>

<script>
import { userInfo } from '@/api/sys/user'
import { getUUID, getTokenName } from '@/utils/index'
import { encryptDes } from '@/utils/des'
import { login, getCaptchaUrl } from '@/api/sys/user'
import { listByUserid } from '@/api/hbi/project/bscreen.js'
import { getValue } from '@/api/sys/config'

export default {
  data() {
    return {
      value: '',

      dataForm: {
        userNo: '',
        password: '',
        uuid: '',
        captcha: ''
      },

      dataRule: {
        userNo: [{ required: true, message: '登录账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '登录密码不能为空', trigger: 'blur' }],
        captcha: [{ required: true, message: '验证码不能为空', trigger: 'blur' }]
      },

      captchaPath: '',
      logining: false
    }
  },
  created() {
    this.getCaptcha()
    this.getKey()
  },
  methods: {
    getbscreens() {
      listByUserid()
        .then(({ data }) => {
          if (data && data.code == 0) {
            let id = ''
            if (data.data != null && data.data.length > 0) {
              id = data.data[0].bscreenId

              // 查询参数
              const query = { id: id }

              if (this.$store.state.user.regionCode != '1000') {
                query.adCode = this.$store.state.user.adcode
              }

              this.$router.push({
                path: '/stat',
                query
              })
            } else {
              this.$router.replace({ name: 'home' }).catch(e2 => {})
            }
          } else if (data && data.msg) {
            this.$message.error(data.msg)
          }
        })
        .finally(() => {
          this.categoryLoading = false
        })
    },

    // 提交表单
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.logining = true
          setTimeout(() => {
            this.loginRequest()
          }, 30);
        }
      })
    },
    loginRequest() {
      // 加密密码
      const pass = encryptDes(this.dataForm.password)
      login(this.dataForm.userNo.trim(), pass, this.dataForm.uuid, this.dataForm.captcha.trim())
        .then(({ data }) => {
          if (data && data.code === 0) {
            const tokenName = getTokenName()
            this.$cookie.set(tokenName, data.data.token)
            this.$cookie.set('Username', data.data.user.username)
            this.$cookie.set('roleId', data.data.user.roleId)

            userInfo()
              .then(({ data }) => {
                if (data && data.code === 0) {
                  const userData = data.data
                  this.$store.commit('user/setUser', userData)
                  this.getbscreens()
                } else {
                  this.$message.error('获取用户信息失败: ' + data.msg)
                }
              })
              .catch(error => {
                this.$message.error('获取用户信息失败')
              })
          } else {
            this.logining = false
            this.getCaptcha()
            this.dataForm.captcha = ''
            this.$message.error(data.msg)
          }
        })
        .catch(err => {
          this.$message.error(err)
          this.logining = false
        })
    },

    //配置登录页面的名称
    getKey() {
      const key = 'login'
      //debugger
      getValue(key)
        .then(({ data }) => {
          if (data && data.code == 0) {
            this.value = data.data
            console.log(this.value)
          } else if (data && data.msg) {
            this.$message.error(data.msg)
          }
        })
        .catch(e => {})
    },

    // 获取验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID()
      this.captchaPath = getCaptchaUrl(this.dataForm.uuid)
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  //background: url(~@/assets/img/login-bg.png) no-repeat 0;
  &:before {
    position: fixed;
    top: 100;
    left: 100;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background-size: cover;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  .auth-content {
    position: absolute;
    z-index: 1;
    width: 500px;
    right: 25%;
    margin-right: -200px;
    top: 50%;
    margin-top: -220px;

    .login-page {
      position: relative;
      height: 100%;

      display: -webkit-box;
      display: -ms-flexbox;

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      .login-form {
        width: 500px;
        padding: 50px;
        border: 1px solid #bfe1fd;
        background-color: #2273de;
        /*box-shadow: 10px 10px 5px #888888;*/
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        .form-content {
          padding: 0px 40px;
        }

        .login-title-new {
          letter-spacing: 3px;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          margin-bottom: 30px;
          color: #ffffff;
          text-align: center;
        }

        input {
          height: 47px;
        }

        .login-form-header {
          text-align: center;
          margin-top: 5rem;
        }

        .login-item-center {
          text-align: center;
          margin-top: 20px;

          ::v-deep.el-button--primary {
            background: linear-gradient(-90deg, #50b9fb, #7697fe);
            border-radius: 28px;
          }

          ::v-deep.el-button--primary:active {
            background: #7697fe;
          }
        }

        .login-item-center button {
          width: 100%;
        }

      }
    }
  }

  .auth-footer {
    text-align: center;
    line-height: 30px;
    position: absolute;

    bottom: 10%;
    left: 60%;
    margin-left: -150px;

    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1a1a1a;
  }
}

.el-row.input__wrappper {
  background-color: white;
  padding: 5px 20px;
  padding-top: 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  border: 5px solid rgb(87, 157, 250);

  .validate__img {
    border-radius: 5px;
  }

  ::v-deep.el-input__icon.el-input__validateIcon.el-icon-circle-check {
    color: rgb(79, 160, 110);
  }
}

@media screen and (max-width: 768px) {
  .login-page {
    background: none;
  }
}
</style>
